import Heading from "Components/Heading";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form } from "react-bootstrap";
import { TbClipboardCheck } from "react-icons/tb";
import Footer from "Components/Footer";
import Loader from "Components/Loader";
import { useSelector } from "react-redux";
import TicketAPIs from "../../APIs/ticket";
import { toast } from "react-toastify";
import SampleDataTable from "Components/DataTables";
import SearchForm from "Components/SearchForm";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import AuthAPIs from "../../APIs/auth";
import { formatDate } from "Helper/Converters";
import { MdClose } from "react-icons/md";

const CloseTickets = () => {
  const [checkedItems, setCheckedItems] = useState({});
  const [user, setUser] = useState([]);
  const [users, setUsers] = useState([]);
  const [unAssignedTicket, setUnAssignedTicket] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useSelector((state) => state.auth);
  const [searchedMasterData, setSearchedMasterData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedTicket, setSelectedTicket] = useState();
  const [stats, setStats] = useState();
  const [successMsg, setSuccessMsg] = useState("");

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px", // Set the minimum height as needed
    }),
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems({ ...checkedItems, [name]: checked });
  };

  const handleSelectionChange = (selectedIds) => {
    console.log("Selected IDs:", selectedIds);
    setSelectedIds(selectedIds);
  };

  const getTicketStats = async () => {
    setIsLoading(true);
    const res = await TicketAPIs.ticketStats();
    if (res) {
      setStats(res.data.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUser(user);
    getTicketStats();
  }, []);

  const columns = [
    {
      accessor: "ticketNo",
      Header: "Ticket No",
      Cell: ({ row }) => (
        <a
          onClick={(e) => {
            e.preventDefault();
            const url = `/dashboard/ticket-detail/${row.original.ticketNo}`;
            // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
            const windowName = `popUpWindow_${row.original.ticket}`;
            const windowSize = "width=800,height=600";
            window.open(url, windowName, windowSize);
          }}
          href={`/dashboard/ticket-detail/${row.original.ticketNo}`}
          rel="noopener noreferrer"
          style={{ cursor: "pointer" }}
        >
          {row.original.ticketNo}
        </a>
      ),
    },
    { accessor: "location", Header: "Location" },
    { accessor: "entered", Header: "Entered" },
    // {
    //   accessor: "assigntoo",
    //   Header: "Assigned To",
    //   Cell: ({ row }) => <p>{row.original.assigntoo?.firstName}</p>,
    // },
    // {
    //   accessor: "assign",
    //   Header: "Assigned Date",
    // },
    // {
    //   accessor: "reassinged",
    //   Header: "Reassigned To",
    //   Cell: ({ row }) => <p>{row.original.reassigned?.firstName}</p>,
    // },
    // { accessor: "reassingndate", Header: "Reassigned Date" },
    {
      accessor: "preCompleteBy",
      Header: "Pre Completed By",
      Cell: ({ row }) => <p>{row.original.preCompleteBy?.firstName}</p>,
    },
    { accessor: "preCompletedDate", Header: "Pre Completed Date" },
    { accessor: "due", Header: "Due" },
    {
      accessor: "code",
      Header: "Station Code",
      Cell: ({ row }) => (
        <p>{row.original.code?.map((item) => item.name).join(", ")}</p>
      ),
    },
    // { accessor: "segmet", Header: "Segment" },
  ];

  const officeClearOrClose = async (type) => {
    console.log(
      "DUCK",
      "closeTicket:officeClearOrClose:searchedData",
      searchedData
    );
    if (searchedData.length > 0) {
      if (!selectedIds[0]) {
        toast.error("No Tickets Selected");
        return;
      }

      if (!user?.id) {
        toast.error("No User Selected");
        return;
      }

      console.log(
        "DUCK",
        "closeTicket:officeClearOrClose:assignUserID",
        user?.id
      );
      setIsLoading(true);
      if (type === "office_clear") {
        const res = await TicketAPIs.officeClear({
          ticketId: selectedIds,
          userId: user?.id,
        });
        if (res) {
          toast.success("Office Clear Successfully");
        }
      } else if (type === "close") {
        const close = await TicketAPIs.closeTickets({
          ticketId: selectedIds,
          userId: user?.id,
        });
        if (close) {
          // toast.success("Tickets Closed Successfully");
          const selectedTicketsNumbers = searchedData
            .filter((item) => selectedIds.indexOf(item.id) > -1)
            .map((item) => item.ticketNo);
          setSuccessMsg(
            `Following Tickets ${selectedTicketsNumbers.join(
              ","
            )} has been closed successfully.`
          );
          getTickets()
        }
      }
      setSelectedIds([]);
      //setSearchedData([]);
      setIsLoading(false);
    }
  };

  const getTickets = async () => {
    const tickets = await TicketAPIs.search({
      preCompletedDate: "notNull",
      completedDate: null,
      isOfficeClear: false,
    });
    if (tickets) {
      setSearchedData(tickets?.data?.data || []);
      setSearchedMasterData(tickets?.data?.data || []);
    }
    setIsLoading(false);
  };

  const getUsers = async () => {
    const users = await AuthAPIs.getAllUsers();
    const customArr = [{ value: "", label: "Select" }];
    if (users) {
      const arr = [...users.data.data];
      arr?.forEach((item) => {
        const data = { value: item?.id, label: item?.firstName };
        if (item?.id !== userId) customArr.push(data);
      });
      setUsers(customArr);
    }
  };

  useEffect(() => {
    getTickets();
    getUsers();
  }, []);

  useEffect(() => {
    selectedUser?.value ?
      setSearchedData(
        searchedMasterData.filter(
          (item) =>
            item.assigntoo?.firstName == selectedUser.label ||
            item.reassinged?.firstName == selectedUser.label
        )
      ) :
      setSearchedData(JSON.parse(JSON.stringify(searchedMasterData)))
  }, [selectedUser]);

  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <section>
          <Heading text={"Close Tickets"} />
          {successMsg && (
            <div className="successMsg">
              <strong style={{ margin: 0, color: "rgb(0,120,0)" }}>
                {successMsg}
              </strong>
              <MdClose
                size={20}
                color={"gray"}
                onClick={() => setSuccessMsg("")}
                style={{ position: "absolute", top: 10, right: 10 }}
              />
            </div>
          )}
          <Card>
            {/* <SearchForm
              setSearchedData={setSearchedData}
              setIsLoading={setIsLoading}
              filters={{
                preCompletedDate: "notNull",
                completedDate: null,
                isOfficeClear: false,
              }}
              loadDataOnMount={true}
              hiddenElements={[
                "typeOfWork",
                "ticketNo",
                "digAddress",
                "digNearestIntersection",
                "contractorName",
                "ticketType",
                "callerName",
                "stationCodes",
                "dateType",
                "fromDate",
                "toDate",
                "assignTo",
                "reAssignTo"
              ]}
            /> */}
            <Row>
              <Col className="column">
                <Form.Group className="form-group">
                  <Form.Label>Users</Form.Label>
                  <Select
                    options={users}
                    styles={customStyles}
                    defaultValue={users[0]}
                    onChange={(selectedOption) => {
                      setSelectedUser(selectedOption);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Col md={12}>
              <Button
                className="me-3 mb-3"
                onClick={() => {
                  officeClearOrClose("close");
                }}
              >
                <TbClipboardCheck />
                Close Tickets
              </Button>
              {/* <Button
                onClick={() => {
                  officeClearOrClose("office_clear");
                }}
              >
                <TbClipboardCheck />
                Office Clear
              </Button> */}
            </Col>

            {searchedData[0] ? (
              <SampleDataTable
                columns={columns}
                data={searchedData}
                onSelectionChange={handleSelectionChange}
              />
            ) : (
              <p style={{ textAlign: "center" }}>No Results Available</p>
            )}
            <Footer />
          </Card>
        </section>
      )}
    </>
  );
};

export default CloseTickets;

import React, { useState, useRef, useEffect } from "react";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { Form } from "react-bootstrap";

const libraries = ["places"];

const AutoComplete = ({ handleChange, field, values, errors, ...props }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBqdKhAkPI_qCHco8_vVsnGrUGYjw7HL3M", // Replace with your API key
    libraries,
  });

  const autocompleteRef = useRef(null);
  const [autocomplete, setAutocomplete] = useState(null);

  const handleLoad = (autoC) => setAutocomplete(autoC);

  const handlePlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      handleChange({ target: { name: field, value: place.name } });
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  useEffect(() => {
    // Apply the styles after the component mounts and Google Places API is loaded
    const applyStyles = () => {
      const pacContainers = document.querySelectorAll(".pac-container");
      pacContainers.forEach((container) => {
        container.style.zIndex = "99999999"; // Ensure this is higher than the modal's z-index
      });
    };

    if (window.google) {
      applyStyles();
    } else {
      window.addEventListener("load", applyStyles);
      return () => window.removeEventListener("load", applyStyles);
    }
  }, []);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Form.Group className="form-group">
      <Form.Label>City Name</Form.Label>
      <Autocomplete
        ref={autocompleteRef}
        onLoad={handleLoad}
        onPlaceChanged={handlePlaceChanged}
      >
        <Form.Control type="text" onChange={handleChange} required {...props} />
      </Autocomplete>
    </Form.Group>
  );
};

export default AutoComplete;

import Heading from "Components/Heading";
import React, { useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { MdDateRange } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import Footer from "Components/Footer";
import HourLogAPIs from "../../APIs/hour-log";
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from "react-toastify";
import * as XLSX from "xlsx-js-style";
import { capitalizeFirstLetter } from "Helper/Converters";
import { format } from "date-fns";
import CustomDatePicker from "Components/CustomDatePicker";

const DATE_TYPE_OPTIONS = [
  { value: "", label: "Select" },
  { value: "completeDate", label: "Completed Date" },
  { value: "enteredDate", label: "Entered Date" },
  { value: "dueDate", label: "Due Date" },
  { value: "assignDate", label: "Assigned Date" },
  { value: "cancelDate", label: "Cancelled Date" },
];

const CONTRACTS_OPTIONS = [
  { value: "", label: "Select" },
  { value: "AURORA", label: "Aurora" },
  { value: "BRAMPTON", label: "Brampton" },
  { value: "PEEL", label: "Peel" },
  { value: "PICKERING", label: "Pickering" },
  { value: "VAUGHAN", label: "Vaughan" },
  { value: "YORK", label: "York" },
];

const CONTRACTS_UTILS_OPTIONS = [
  { value: "", label: "Select" },
  { value: "waterUnit", label: "Water" },
  { value: "stormUnit", label: "Storm" },
  { value: "sanitaryUnit", label: "Sanitary" },
];

const INITIAL_VALUES = {
  dateType: DATE_TYPE_OPTIONS[0],
  fromDate: null,
  toDate: null,
  city: CONTRACTS_OPTIONS[0],
  filterUnit: CONTRACTS_UTILS_OPTIONS[0],
}

const ValidationSchema = Yup.object().shape({
  dateType: Yup.object()
    .shape({
      value: Yup.string().required('Date Type is required'),
    })
    .required('Date Type is required'),
  fromDate: Yup.date().required('Start Date is required'),
  toDate: Yup.date().required('End Date is required'),
  city: Yup.object()
    .shape({
      value: Yup.string().required('City is required'),
    })
    .required('City is required')
});

const Billing = () => {
  const [reports, setReports] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [searchedValues, setSearchedValues] = useState(null)

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px",
    }),
  };

  const fetchBillingData = async (values, setSubmitting) => {
    try {
      setReports([])
      setTickets([])
      setSearchedValues(JSON.parse(JSON.stringify(values)))
      // Assuming getQueryHourLog is a function that fetches data and returns a response similar to the one you provided
      values = {
        ...values,
        fromDate:format(new Date(values?.fromDate), "yyyy-MM-dd"),
        toDate:format(new Date(values?.toDate), "yyyy-MM-dd")
      }
      const response = await HourLogAPIs.getBillingReport(values);
      console.log("DUCK", "fetchBillingData", response);
      if (response && response.data.success && response.data.data.report.length > 0) {
        if (values.city.value == 'PEEL' && values.filterUnit.value) {
          let code = {}
          setReports(response.data.data.report.map(item => {
            code[item.STATION_CODE] = code[item.STATION_CODE] ? ++code[item.STATION_CODE] : 1
            return {
              ...item,
              code: ` - ${code[item.STATION_CODE]} code`
            }
          }))
        } else {
          setReports(response.data.data.report)
        }
        setTickets(response.data.data.tickets || [])
      } else {
        toast.error('No Records Found Yet!')
      }
    } catch (error) {
      console.error("Error fetching hour logs:", error);
    } finally {
      setSubmitting(false)
    }
  };

  const formatDateForExcel = () => {
    let formattedData = []
    reports.forEach(item => {
      if (item.hasOwnProperty('OFFICE_CLEAR_COUNT')) {
        formattedData.push(
          [
            { v: `Office Clear - ${item.STATION_CODE}`, t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
            { v: "Quantity", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
          ],
          [
            { v: 'Office Clear Notification' },
            { v: item.OFFICE_CLEAR_COUNT },
          ],
          [
            { v: 'SUBTOTAL' },
            { v: item.OFFICE_CLEAR_COUNT },
          ]
        )
      } else {
        formattedData.push(
          [
            { v: `${capitalizeFirstLetter(item.UNIT?.replace(/Unit/, ''))} - ${item.STATION_CODE}`, t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
            { v: "Quantity", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
          ],
          [
            { v: 'Standard and Priority Request' },
            { v: item.HOUR_LOG },
          ],
          [
            { v: 'Emergencies During Working Hours' },
            { v: item.PER_TICKET },
          ],
          [
            { v: 'Emergencies After Hours' },
            { v: item.AHE_TICKET },
          ],
          [
            { v: 'SUBTOTAL' },
            { v: item.HOUR_LOG + item.PER_TICKET + item.AHE_TICKET },
          ],
          [
            { v: 'Ticket(s) Count' },
            { v: item.TOTAL_TICKETS },
          ],
        )
      }
    })
    formattedData.push([])
    formattedData.push(
      [
        { v: "TicketNumber", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: "Address", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: "Contractor", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: "Type of Ticket", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: "Entered Date", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: "Station Code", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: "During Hours Emergency Electrical Unit", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: "After Hours Emergency Electrical Unit", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: "Regular Electrical Unit", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: "During Hours Emergency StreetLight Unit", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: "After Hours Emergency StreetLight Unit", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: "Regular StreetLight Unit", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: "During Hours Emergency TrafficLight Unit", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: "After Hours Emergency TrafficLight Unit", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: "Regular TrafficLight Unit", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: "During Hours Emergency ZUM Unit", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: "After Hours Emergency ZUM Unit", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: "Regular ZUM Unit", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        // { v: "During Hours Emergency Emergency Storm Unit", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        // { v: "After Hours Emergency Emergency Storm Unit", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        // { v: "Regular Emergency Storm Unit", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
        { v: searchedValues.city.value == 'BRAMPTON' ? "Office Clear" : "", t: "s", s: { font: { bold: true, sz: 16, color: { rgb: "0068af" } } } },
      ]
    )
    tickets.forEach(item => {
      if (item)
        formattedData.push([
          { v: item.ticketNo },
          { v: item.address },
          { v: item.company_name },
          { v: item.ticketType },
          { v: format(item.createdAt, "dd/MM/yyyy") },
          { v: item.stationCodes?.map(code => code.name).join(', ') },
          { v: item.logType == 'PER_TICKET' && item.ticketPreCompletes?.[0]?.electricalUnit || 0 },
          { v: item.logType == 'AHE_TICKET' && item.ticketPreCompletes?.[0]?.electricalUnit || 0 },
          { v: item.logType == 'HOUR_LOG' && item.ticketPreCompletes?.[0]?.electricalUnit || 0 },
          { v: item.logType == 'PER_TICKET' && item.ticketPreCompletes?.[0]?.streetLightUnit || 0 },
          { v: item.logType == 'AHE_TICKET' && item.ticketPreCompletes?.[0]?.streetLightUnit || 0 },
          { v: item.logType == 'HOUR_LOG' && item.ticketPreCompletes?.[0]?.streetLightUnit || 0 },
          { v: item.logType == 'PER_TICKET' && item.ticketPreCompletes?.[0]?.trafficLightUnit || 0 },
          { v: item.logType == 'AHE_TICKET' && item.ticketPreCompletes?.[0]?.trafficLightUnit || 0 },
          { v: item.logType == 'HOUR_LOG' && item.ticketPreCompletes?.[0]?.trafficLightUnit || 0 },
          { v: item.logType == 'PER_TICKET' && item.ticketPreCompletes?.[0]?.zumUnit || 0 },
          { v: item.logType == 'AHE_TICKET' && item.ticketPreCompletes?.[0]?.zumUnit || 0 },
          { v: item.logType == 'HOUR_LOG' && item.ticketPreCompletes?.[0]?.zumUnit || 0 },
          // { v: item.logType == 'HOUR_LOG' && item.ticketPreCompletes?.[0]?.electricalUnit || 0 },
          // { v: item.logType == 'PER_TICKET' && item.ticketPreCompletes?.[0]?.electricalUnit || 0 },
          // { v: item.logType == 'AHE_TICKET' && item.ticketPreCompletes?.[0]?.electricalUnit || 0 },
          { v: searchedValues.city.value == 'BRAMPTON' ? (item.isOfficeClear ? 1 : 0) : '' },
        ])
    })

    return formattedData
  }

  const exportToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();

    // Convert your data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(formatDateForExcel());

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

    // Export the workbook
    XLSX.writeFile(workbook, `Billing_Report.xlsx`);
  };

  return (
    <>
      <section>
        <Heading text={"Billing"} />

        <Card>
          <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={ValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              fetchBillingData(values, setSubmitting)
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              /* and other goodies */
            }) => {
              return (
                <Form className="dashboard-form mb-4" onSubmit={handleSubmit}>
                  <Row>
                    <Col className="column">
                      <Form.Group className="form-group">
                        <Form.Label>Date Type</Form.Label>
                        <Select
                          name="dateType"
                          options={DATE_TYPE_OPTIONS}
                          value={values.dateType}
                          styles={customStyles}
                          onChange={val => setFieldValue('dateType', val)}
                          onBlur={() => setFieldTouched('dateType', true, true)}
                        />
                        {touched.dateType && errors.dateType?.value && <span className="error-msg">{errors.dateType.value}</span>}
                      </Form.Group>
                    </Col>
                    <Col className="column">
                      <Form.Group className="form-group">
                        <Form.Label>Start Date</Form.Label>
                        <div className="datepicker_box">
                          <CustomDatePicker
                            name="fromDate"
                            selected={values.fromDate}
                            onSelect={date => { setFieldValue('fromDate', date); setFieldValue('toDate', null) }}
                            dateFormat="MM/dd/yyyy" // Adjust the date format as needed
                            onBlur={() => setFieldTouched('fromDate', true, true)}
                          />
                          <span>
                            <MdDateRange />
                          </span>
                        </div>
                        {touched.fromDate && errors.fromDate && <span className="error-msg">{errors.fromDate}</span>}
                      </Form.Group>
                    </Col>
                    <Col className="column">
                      <Form.Group className="form-group">
                        <Form.Label>Date To</Form.Label>
                        <div className="datepicker_box">
                          <CustomDatePicker
                            name="toDate"
                            minDate={values.fromDate}
                            selected={values.toDate}
                            onSelect={date => setFieldValue('toDate', date)}
                            dateFormat="MM/dd/yyyy" // Adjust the date format as needed
                            onBlur={() => setFieldTouched('toDate', true, true)}
                          />
                          <span>
                            <MdDateRange />
                          </span>
                        </div>
                        {touched.toDate && errors.toDate && <span className="error-msg">{errors.toDate}</span>}
                      </Form.Group>
                    </Col>
                    <Row className="pe-0">
                      <Col md={6}>
                        <Form.Group className="form-group">
                          <Form.Label>Contracts</Form.Label>
                          <Select
                            name="city"
                            options={CONTRACTS_OPTIONS}
                            value={values.city}
                            styles={customStyles}
                            onChange={val => setFieldValue('city', val)}
                            onBlur={() => setFieldTouched('city', true, true)}
                          />
                          {touched.city && errors.city?.value && <span className="error-msg">{errors.city.value}</span>}
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-3 pe-0">
                        <Form.Group className="form-group">
                          <Form.Label>Contract Utilities</Form.Label>
                          <Select
                            name="filterUnit"
                            options={CONTRACTS_UTILS_OPTIONS}
                            value={values.filterUnit}
                            styles={customStyles}
                            onChange={val => setFieldValue('filterUnit', val)}
                            onBlur={() => setFieldTouched('filterUnit', true, true)}
                          />
                          {touched.filterUnit && errors.filterUnit?.value && <span className="error-msg">{errors.filterUnit.value}</span>}
                        </Form.Group>
                      </Col>
                      <Col md={12} className="d-flex justify-content-between">
                        {reports.length > 0 ?
                          <Button onClick={exportToExcel} >
                            Export
                          </Button> :
                          <span />
                        }
                        <Button type="submit" disabled={isSubmitting} >
                          <BiSearch />
                          {isSubmitting ? 'Loading...' : 'Search'}
                        </Button>
                      </Col>
                    </Row>
                  </Row>
                </Form>
              )
            }}
          </Formik>
          {reports.map((item, index) => {
            if (item.hasOwnProperty('OFFICE_CLEAR_COUNT')) {
              return (
                <Table striped bordered hover className="mt-3 billing-report-custom-table">
                  <thead>
                    <tr>
                      <th><span>Office Clear - {item.STATION_CODE}</span></th>
                      <th><span>Quantity</span></th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr>
                      <td>Office Clear Notification</td>
                      <td >{item.OFFICE_CLEAR_COUNT}</td>
                    </tr>
                    <tr>
                      <td>SUBTOTAL</td>
                      <td >{item.OFFICE_CLEAR_COUNT}</td>
                    </tr>
                  </tbody>
                </Table>
              )
            }
            return (
              <Table striped bordered hover className="mt-3 billing-report-custom-table">
                <thead>
                  <tr>
                    <th><span>{item.UNIT?.replace(/Unit/, '')} - {item.STATION_CODE} {item.code || ''}</span></th>
                    <th><span>Quantity</span></th>
                  </tr>
                </thead>
                <tbody >
                  <tr>
                    <td>Standard and Priority Request</td>
                    <td >{item.HOUR_LOG}</td>
                  </tr>
                  <tr>
                    <td>Emergencies During Working Hours</td>
                    <td >{item.PER_TICKET}</td>
                  </tr>
                  <tr>
                    <td>Emergencies After Hours</td>
                    <td >{item.AHE_TICKET}</td>
                  </tr>
                  <tr>
                    <td>SUBTOTAL</td>
                    <td >{item.HOUR_LOG + item.PER_TICKET + item.AHE_TICKET}</td>
                  </tr>
                  <tr>
                    <td>Ticket(s) Count</td>
                    <td >{item.TOTAL_TICKETS}</td>
                  </tr>
                </tbody>
              </Table>
            )
          })}
          <Footer />
        </Card>
      </section>
    </>
  );
};

export default Billing;

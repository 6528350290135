import React, { useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";

import classes from "./index.module.scss";
import { Col, Row, Form } from "react-bootstrap";
const SampleDataTable = ({
  columns,
  data,
  style,
  maxWidth,
  center,
  onSelectionChange,
}) => {
  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckboxChange = (id) => {
    const isSelected = selectedIds.includes(id);

    if (isSelected) {
      setSelectedIds((prevIds) => prevIds.filter((prevId) => prevId !== id));
    } else {
      setSelectedIds((prevIds) => [...prevIds, id]);
    }
  };

  const extendedColumns = React.useMemo(() => {
    if (onSelectionChange !== undefined)
      // Append the checkbox column to the provided columns
      return [
        {
          id: "selection", // Give a unique id to the column
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <input
              type="checkbox"
              onChange={(e) => {
                // Prevent sorting when clicking the checkbox
                e.stopPropagation();
                const allRowsSelected = e.target.checked;
                if (allRowsSelected) {
                  setSelectedIds(data.map((row) => row.id));
                } else {
                  setSelectedIds([]);
                }
              }}
              checked={selectedIds.length === data.length}
            />
          ),
          Cell: ({ row }) => (
            <input
              type="checkbox"
              onChange={() => handleCheckboxChange(row.original.id)}
              checked={selectedIds.includes(row.original.id)}
            />
          ),
          accessor: "selection",
        },
        ...columns,
      ];
    else
      return [
        {
          id: "selection", // Give a unique id to the column
          Header: ({ getToggleAllRowsSelectedProps }) => <p>Sr.</p>,
          Cell: ({ row, index }) => <p>{index}</p>,
          accessor: "selection",
        },
        ...columns,
      ];
  }, [columns, data, selectedIds]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    page,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex, pageSize },
    setPageSize,
  } = useTable(
    {
      columns: extendedColumns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { globalFilter } = state;

  React.useEffect(() => {
    console.log("DUCK", "onSelectionChange", onSelectionChange);
    if (onSelectionChange) onSelectionChange(selectedIds);
  }, [onSelectionChange, selectedIds]);

  return (
    <div>
      <Row className="mb-3">
        <Col md={3}>
          <Form.Label style={{ fontSize: "14px", color: "#000" }}>
            Total Tickets Found{" "}
            <span style={{ color: "#757575", fontWeight: "bold" }}>
              {data?.length}
            </span>
          </Form.Label>
        </Col>
      </Row>
      <div className={classes.search}>
        <input
          type="text"
          placeholder="Search..."
          className="form-control search"
          value={globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
        <button className={classes.searchBtn}>
          <i className="fas fa-search"></i>
        </button>
      </div>

      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, rowNum) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, index) => (
                  <td {...cell.getCellProps()} key={index}>
                    {index === 0 && onSelectionChange !== undefined ? (
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange(row.original.id)}
                        checked={selectedIds.includes(row.original.id)}
                      />
                    ) : onSelectionChange === undefined && index === 0 ? (
                      <p>{rowNum + 1}</p>
                    ) : (
                      cell.render("Cell")
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="d-flex align-items-center justify-content-between py-3">
        <div>
          <span className="me-4">
            Page
            <strong className="ms-2">
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <span>
            <span>
              Show
              <select
                value={pageSize}
                className="mx-2"
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[5, 10, 15, 20].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
              entries
            </span>
          </span>
        </div>
        <div className="d-flex align-items-center">
          <span className="me-2">Go to page:</span>
          <input
            type="number"
            style={{
              width: "50px",
              border: "1px solid #ccc",
              padding: "4px 0",
            }}
            defaultValue={pageIndex + 1}
            className="text-center"
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
          <button
            className="btn btn-table"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {"<<"}
          </button>
          <button
            className="btn btn-table"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {"<"}
          </button>

          <button
            className="btn btn-table"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {">"}
          </button>
          <button
            className="btn btn-table"
            onClick={() => gotoPage(pageOptions.length - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SampleDataTable;

import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import Heading from "Components/Heading";
import Footer from "Components/Footer";
import SampleDataTable from "Components/DataTables";
import StationCodesAPIs from "../../APIs/station-code"; // Adjust this import to your actual API file
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import { FaRegEdit, FaRegTrashAlt } from "react-icons/fa";
import AutoComplete from "Components/AutoCompleteCity";
import StationCodeModal from "./components/StationCodeModal";

const StationCode = () => {
  const [stationCodes, setStationCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [currentEditItem, setCurrentEditItem] = useState(null);

  // To open modal in add mode
  const handleShowAdd = () => {
    setModalMode("add");
    setCurrentEditItem(null);
    setShowModal(true);
  };

  // To open modal in edit mode
  const handleShowEdit = (item) => {
    setModalMode("edit");
    setCurrentEditItem(item);
    setShowModal(true);
  };

  const editStationCode = async (data, resetForm) => {
    setIsLoading(true);
    try {
      // Assuming your API has an editDocTypeById method
      await StationCodesAPIs.updateStationCodeById(currentEditItem.id, data);
      toast.success("Station Code Updated Successfully.");
      setShowModal(false); // Close modal after submission
      getAllStationCode(); // Refresh the list
    } catch (error) {
      toast.error("Failed to update Station Code.");
    }
    setIsLoading(false);
  };

  const submitHandler = async (data, resetForm) => {
    setIsLoading(true);
    const res = await StationCodesAPIs.createStationCode(data);
    if (res) {
      toast.success("Station Code Added Successfully.");
      resetForm({ values: "" });
      setShowModal(false); // Close modal after submission
      getAllStationCode();
    }
    setIsLoading(false);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    city: Yup.string().required("City is required"),
    isOfficeClear: Yup.bool(),
  });

  const getAllStationCode = async () => {
    const stationcode = await StationCodesAPIs.getStationCode();
    if (stationcode) {
      setStationCodes(stationcode.data.data);
      console.log(
        "DUCK",
        "getAllStationCode:stationcode",
        stationcode.data.data
      );
    }
  };

  useEffect(() => {
    getAllStationCode();
  }, []);

  return (
    <>
      <section>
        <Heading text={"Station Codes"} />
        <Card>
          <Button
            variant="primary"
            onClick={handleShowAdd}
            size="sm"
            style={{ width: "150px", marginBottom: "20px" }}
          >
            Add
          </Button>

          {/* Modal for Adding/Editing Station Codes */}
          {/* <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {modalMode === "add" ? "Add Station Code" : "Edit Station Code"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Formik
                initialValues={{
                  name: currentEditItem ? currentEditItem.name : "",
                  city: currentEditItem ? currentEditItem.city : "",
                  isOfficeClear: currentEditItem
                    ? currentEditItem.isOfficeClear
                    : false,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                  if (modalMode === "add") {
                    submitHandler(values, resetForm); // Your existing add functionality
                  } else {
                    // Call edit API
                    editStationCode(values, resetForm);
                  }
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  values,
                  errors,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        isInvalid={!!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <AutoComplete
                        name={"city"}
                        field="city"
                        handleChange={handleChange}
                        values={values.city}
                        errors={errors.city}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.city}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Check
                        type="checkbox"
                        label="Is Office Clear"
                        name="isOfficeClear"
                        checked={values.isOfficeClear}
                        onChange={() =>
                          setFieldValue("isOfficeClear", !values.isOfficeClear)
                        }
                      />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                      Save Changes
                    </Button>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal> */}
          <StationCodeModal
            showModal={showModal}
            setShowModal={setShowModal}
            modalMode={modalMode}
            currentEditItem={currentEditItem}
            submitHandler={submitHandler}
            editStationCode={editStationCode}
          />
          {/* Listing Table */}
          <SampleDataTable
            columns={[
              { accessor: "name", Header: "Station Code" },
              { accessor: "city", Header: "City" },
              { accessor: "message", Header: "Message" },
              {
                accessor: "id",
                Header: "Action",
                Cell: ({ row }) => (
                  <>
                    <Button
                      className="me-3"
                      onClick={() => handleShowEdit(row.original)}
                    >
                      <FaRegEdit />
                    </Button>
                    {/* <Button
                      variant="danger"
                      className="me-3"
                      onClick={async () => {
                        const isConfirmed = window.confirm(
                          "Are you sure you want to delete this Reason?"
                        );
                        if (isConfirmed) {
                          // Call delete API

                          setIsLoading(true);
                          try {
                            await StationCodesAPIs.deleteStationCodeById(
                              row.original.id
                            );
                            toast.success("Station Code Deleted Successfully.");
                            getAllStationCode(); // Refresh the list
                          } catch (error) {
                            toast.error("Failed to delete Reason.");
                          }
                          setIsLoading(false);
                        }
                      }}
                    >
                      <FaRegTrashAlt />
                    </Button> */}
                  </>
                ),
              },
            ]}
            data={stationCodes}
          />

          <Footer />
        </Card>
      </section>
    </>
  );
};

export default StationCode;

import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import AutoComplete from "Components/AutoCompleteCity";

const StationCodeModal = ({
  showModal,
  setShowModal,
  modalMode,
  currentEditItem,
  submitHandler,
  editStationCode,
}) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    city: Yup.string().required("City is required"),
    isOfficeClear: Yup.bool(),
  });

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          {modalMode === "add" ? "Add Station Code" : "Edit Station Code"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            name: currentEditItem ? currentEditItem.name : "",
            city: currentEditItem ? currentEditItem.city : "",
            password: currentEditItem ? currentEditItem.password : "",
            message: currentEditItem ? currentEditItem.message : "",
            isOfficeClear: currentEditItem
              ? currentEditItem.isOfficeClear
              : false,
            isFilterable: currentEditItem
              ? currentEditItem.isFilterable
              : false,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            if (modalMode === "add") {
              submitHandler(values, resetForm);
            } else {
              editStationCode(values, resetForm);
            }
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <AutoComplete
                  name={"city"}
                  field="city"
                  handleChange={handleChange}
                  values={values.city}
                  errors={errors.city}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.city}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="text"
                  name="password"
                  onChange={handleChange}
                  value={values.password}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  type="text"
                  name="message"
                  onChange={handleChange}
                  value={values.message}
                  isInvalid={!!errors.message}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Is Office Clear"
                  name="isOfficeClear"
                  checked={values.isOfficeClear}
                  onChange={() =>
                    setFieldValue("isOfficeClear", !values.isOfficeClear)
                  }
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Is Filterable"
                  name="isFilterable"
                  checked={values.isFilterable}
                  onChange={() =>
                    setFieldValue("isFilterable", !values.isFilterable)
                  }
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default StationCodeModal;

import Heading from "Components/Heading";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { MdDateRange } from "react-icons/md";
import { AiOutlineSave } from "react-icons/ai";
import Footer from "Components/Footer";
import { Formik } from "formik";
import * as Yup from "yup";

import TicketAPIs from "../../APIs/ticket";
import StationCodesAPIs from "../../APIs/station-code";
import TicketTypesAPIs from "../../APIs/ticket-types";

import Loader from "Components/Loader";
import { toast } from "react-toastify";
import AutoComplete from "Components/AutoCompleteCity";
import { FaRegPlusSquare } from "react-icons/fa";
import StationCodeModal from "Pages/StationCode/components/StationCodeModal";
import CustomDatePicker from "Components/CustomDatePicker";

const CreateNewTicketLocal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [stationCodes, setStationCodes] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [value, onChange] = useState("10:00");

  const [showModal, setShowModal] = useState(false);

  const validationSchema = Yup.object().shape({
    ticketType: Yup.string().required("Ticket Type. is required"),
    ticketNo: Yup.string().required("Ticket no. is required"),
    //referenceTicketNo: Yup.string().required("Contractor name is required"),
    contractorName: Yup.string().required("Contractor name is required"),
    primaryContact: Yup.string().required("Primary contact is required"),
    phone: Yup.string()
      .required("Phone is required")
      .matches(/^[0-9]+$/, "Phone must contain only digits")
      .min(8, "Phone must be at least eight characters long"),
    //alternateContact: Yup.string().required("Alternate contact is required"),
    //contractorFaxNo: Yup.string().required("Contractor fax no is required"),
    email: Yup.string()
      .email("Invalid Email Address")
      .required("Email is required."),
    ccEmail: Yup.string()
      .email("Invalid Email Address")
      .required("Email is required."),
    dueDate: Yup.string().required("Due date is required."),
    dueTime: Yup.string().required("Due time is required."),
    locationLat: Yup.number().required("Location Latitude is required"),
    locationLng: Yup.number().required("Location Longitude is required"),
    nearestInteraction: Yup.string().required(
      "Nearest Interaction is required"
    ),
    city: Yup.string().required("City is required"),
    typeOfWork: Yup.string().required("Type of work is required"),
    workDoneFor: Yup.string().required("Work done for is required"),
    workDepth: Yup.string().required("Work depth is required"),
  });
  function generateTicketNumber() {
    // Get current date
    const now = new Date();
    const year = now.getFullYear();
    const month = `0${now.getMonth() + 1}`.slice(-2); // Add leading zero
    const day = `0${now.getDate()}`.slice(-2); // Add leading zero
    const formattedDate = `${year}${month}${day}`;

    // Generate a 6-digit number based on milliseconds to ensure it's related to the current time
    const timePart = now.getTime() % 1000000; // Get the last 6 digits of the timestamp

    return `LOC${formattedDate}${timePart}`;
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px",
    }),
  };

  const getAllStationCodes = async () => {
    const stationCodes = await StationCodesAPIs.getStationCode();

    const customArr = [];
    if (stationCodes) {
      const arr = [...stationCodes.data.data];
      arr?.forEach((item) => {
        const data = {
          value: item?.id,
          label: `${item?.name}`,
        };
        customArr.push(data);
      });
      setStationCodes(customArr);
    }
  };

  const getAllTicketTypes = async () => {
    const types = await TicketTypesAPIs.getTicketType();

    const customArr = [];
    if (types) {
      const arr = [...types.data.data];
      arr?.forEach((item) => {
        const data = {
          value: item?.id,
          label: `${item?.name}`,
        };
        customArr.push(data);
      });
      setTicketTypes(customArr);
    }
  };

  const submitHandler = async (data) => {
    console.log("DUCK", "submitHandler", JSON.stringify(data));
    setIsLoading(true);
    const res = await TicketAPIs.createTicket(data);
    if (res) {
      console.log("Ticket Created Successfully === ", res.data);
      toast.success("Ticket Created Successfully.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAllStationCodes();
    getAllTicketTypes();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <section>
          <Heading text={"Create New Ticket (Local)"} btn />
          <Card>
            <Formik
              onSubmit={(values) => {
                submitHandler(values);
              }}
              initialValues={{
                ticketType: "",
                ticketNo: generateTicketNumber(),
                referenceTicketNo: "",
                wapNo: "",
                contractorName: "",
                primaryContact: "",
                phone: "",
                alternateContact: "",
                contractorFaxNo: "",
                email: "",
                ccEmail: "",
                dueDate: new Date(),
                dueTime: new Date(),
                locationLat: "",
                locationLng: "",
                nearestInteraction: "",
                city: "",
                typeOfWork: "",
                workDoneFor: "",
                workDepth: "",
                additionalInfo: "",
              }}
              validationSchema={validationSchema}
              validateOnChange={false}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
                setFieldValue,
              }) => (
                <Form
                  className="dashboard-form mb-4"
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <Row>
                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Label>Ticket Type</Form.Label>
                        <Select
                          name={"ticketType"}
                          options={ticketTypes}
                          styles={customStyles}
                          onChange={(selectedOptions) => {
                            console.log(
                              "DUCK",
                              "ticketType",
                              selectedOptions.label
                            );
                            setFieldValue("ticketType", selectedOptions.label);
                          }}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.ticketType}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Ticket No</Form.Label>
                        <Form.Control
                          type="text"
                          name={"ticketNo"}
                          onChange={handleChange}
                          value={values.ticketNo}
                          required
                          disabled
                          isInvalid={!!errors.ticketNo}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.ticketNo}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Reference Ticket Number</Form.Label>
                        <Form.Control
                          type="text"
                          name={"referenceTicketNo"}
                          onChange={handleChange}
                          value={values.referenceTicketNo}
                          isInvalid={!!errors.referenceTicketNo}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.referenceTicketNo}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>WAP Number</Form.Label>
                        <Form.Control
                          type="text"
                          name={"wapNo"}
                          onChange={handleChange}
                          value={values.wapNo}
                          isInvalid={!!errors.wapNo}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.wapNo}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Station Code</Form.Label>
                        <Row>
                          <Col xs={9} md={10}>
                            <Select
                              isMulti
                              name={"stationCodeIds"}
                              options={stationCodes}
                              styles={customStyles}
                              onChange={(selectedOptions) => {
                                console.log(
                                  "DUCK",
                                  "selectedOptions",
                                  selectedOptions
                                );
                                setFieldValue(
                                  "stationCodeIds",
                                  selectedOptions.map((option) => option.value)
                                );
                              }}
                            />
                          </Col>
                          <Col
                            xs={2}
                            md={1}
                            className="d-flex align-items-center"
                          >
                            <Button
                              variant="primary"
                              className=""
                              onClick={() => setShowModal(true)}
                            >
                              <FaRegPlusSquare />
                            </Button>
                          </Col>
                        </Row>
                        <Form.Control.Feedback type="invalid">
                          {errors.stationCode}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <h3>Caller Information</h3>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Contractor Name</Form.Label>
                        <Form.Control
                          type="text"
                          name={"contractorName"}
                          onChange={handleChange}
                          value={values.contractorName}
                          required
                          isInvalid={!!errors.contractorName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.contractorName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Primary Contact</Form.Label>
                        <Form.Control
                          type="text"
                          name={"primaryContact"}
                          onChange={handleChange}
                          value={values.primaryContact}
                          required
                          isInvalid={!!errors.primaryContact}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.primaryContact}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="tel"
                          name={"phone"}
                          onChange={handleChange}
                          value={values.phone}
                          required
                          isInvalid={!!errors.phone}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Alternate Contact</Form.Label>
                        <Form.Control
                          type="text"
                          name={"alternateContact"}
                          onChange={handleChange}
                          value={values.alternateContact}
                          isInvalid={!!errors.alternateContact}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.alternateContact}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Contractor Fax No</Form.Label>
                        <Form.Control
                          type="text"
                          name={"contractorFaxNo"}
                          onChange={handleChange}
                          value={values.contractorFaxNo}
                          isInvalid={!!errors.contractorFaxNo}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.contractorFaxNo}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name={"email"}
                          onChange={handleChange}
                          value={values.email}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>CC Email</Form.Label>
                        <Form.Control
                          type="email"
                          name={"ccEmail"}
                          onChange={handleChange}
                          value={values.ccEmail}
                          isInvalid={!!errors.ccEmail}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.ccEmail}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="form-group">
                        <Form.Label>Work To Be Finished By/Due Date</Form.Label>
                        <div className="datepicker_box">
                          <CustomDatePicker
                            showTimeSelect
                            selected={values.dueDate}
                            onSelect={(date) =>
                              handleChange({
                                target: { name: "dueDate", value: date },
                              })
                            }
                            value={values.dueTime}
                            dateFormat="MM/dd/yyyy HH:MM"
                          />
                          <span>
                            <MdDateRange />
                          </span>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <h3>Location INFORMATION</h3>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Location Latitude</Form.Label>
                        <Form.Control
                          type="text"
                          name={"locationLat"}
                          pattern="^-?[0-9]+\.?[0-9]*$"
                          onChange={(e) => {
                            // Remove non-numeric and non-dot characters from the input value
                            const numericValue = e.target.value.replace(
                              /[^-0-9.]/g,
                              ""
                            );
                            // Update the state with the cleaned numeric value
                            handleChange({
                              target: {
                                name: "locationLat",
                                value: numericValue,
                              },
                            });
                          }}
                          value={values.locationLat}
                          isInvalid={!!errors.locationLat}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.locationLat}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Location Longitude</Form.Label>
                        <Form.Control
                          type="text"
                          name={"locationLng"}
                          onChange={(e) => {
                            // Remove non-numeric and non-dot characters from the input value
                            const numericValue = e.target.value.replace(
                              /[^-0-9.]/g,
                              ""
                            );
                            // Update the state with the cleaned numeric value
                            handleChange({
                              target: {
                                name: "locationLng",
                                value: numericValue,
                              },
                            });
                          }}
                          value={values.locationLng}
                          isInvalid={!!errors.locationLng}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.locationLng}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Nearest Interaction</Form.Label>
                        <Form.Control
                          type="text"
                          name={"nearestInteraction"}
                          onChange={handleChange}
                          value={values.nearestInteraction}
                          isInvalid={!!errors.nearestInteraction}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.nearestInteraction}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <AutoComplete
                          name={"city"}
                          field="city"
                          handleChange={handleChange}
                          values={values.city}
                          errors={errors.city}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.city}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Type Of Work</Form.Label>
                        <Form.Control
                          type="text"
                          name={"typeOfWork"}
                          onChange={handleChange}
                          value={values.typeOfWork}
                          isInvalid={!!errors.typeOfWork}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.typeOfWork}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Work Being Done For</Form.Label>
                        <Form.Control
                          type="text"
                          name={"workDoneFor"}
                          onChange={handleChange}
                          value={values.workDoneFor}
                          isInvalid={!!errors.workDoneFor}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.workDoneFor}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Work Depth</Form.Label>
                        <Form.Control
                          type="text"
                          name={"workDepth"}
                          onChange={handleChange}
                          value={values.workDepth}
                          isInvalid={!!errors.workDepth}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.workDepth}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="form-group">
                        <Form.Label>Additional Info</Form.Label>
                        <Form.Control
                          type="text"
                          name={"additionalInfo"}
                          onChange={handleChange}
                          value={values.additionalInfo}
                          isInvalid={!!errors.additionalInfo}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.additionalInfo}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={12} className="text-start">
                      <Button type={"submit"}>
                        <AiOutlineSave />
                        Save
                      </Button>
                    </Col>
                  </Row>
                  <StationCodeModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    modalMode={"add"}
                    currentEditItem={null}
                    submitHandler={async (data, resetForm) => {
                      setIsLoading(true);
                      const res = await StationCodesAPIs.createStationCode(
                        data
                      );
                      if (res) {
                        toast.success("Station Code Added Successfully.");
                        // resetForm({ values: "" });
                        setShowModal(false); // Close modal after submission
                        getAllStationCodes();
                      }
                      setIsLoading(false);
                    }}
                    editStationCode={null}
                  />
                </Form>
              )}
            </Formik>
            <Footer />
          </Card>
        </section>
      )}
    </>
  );
};

export default CreateNewTicketLocal;

import Heading from "Components/Heading";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { MdClose, MdDateRange } from "react-icons/md";
import { BiSearch } from "react-icons/bi";
import { MdOutlineRefresh } from "react-icons/md";
import Footer from "Components/Footer";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import TicketAPIs from "../../APIs/ticket";
import { formatDate } from "Helper/Converters";
import { dateType } from "Helper/dropdowns";
import { toast } from "react-toastify";
import AuthAPIs from "../../APIs/auth";
import DataTable from "Components/CustomTable";
import Loader from "Components/Loader";
import SampleDataTable from "Components/DataTables";
import SearchForm from "Components/SearchForm";
import CustomDatePicker from "Components/CustomDatePicker";

const ResignTicket = () => {
  const [checkedItems, setCheckedItems] = useState({});
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const { userId } = useSelector((state) => state.auth);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [searchedData, setSearchedData] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState();
  const [stats, setStats] = useState();
  const [unAssignedTicket, setUnAssignedTicket] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");

  const handleSelectionChange = (selectedIds) => {
    console.log("Selected IDs:", selectedIds);
    setSelectedIds(selectedIds);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "44px", // Set the minimum height as needed
    }),
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems({ ...checkedItems, [name]: checked });
  };

  const getUsers = async () => {
    const users = await AuthAPIs.getAllUsers();
    const customArr = [{ value: "", label: "Select" }];
    if (users) {
      const arr = [...users.data.data];
      arr?.forEach((item) => {
        const data = { value: item?.id, label: item?.firstName };
        if (item?.id !== userId) customArr.push(data);
      });
      setUsers(customArr);
    }
  };

  const getTickets = async () => {
    const tickets = await TicketAPIs.search({
      assignDate: "notNull",
      reAssignTo: null,
      isOfficeClear: false,
    });
    const customArr = [{ value: "", label: "Select" }];
    console.log("DUCK", "AssignTIckets:getTickets", tickets?.data?.data);
    if (tickets) {
      const arr = [
        ...tickets.data.data.filter((item) => item.assignTo !== null),
      ];
      arr?.forEach((item) => {
        const data = { value: item?.id, label: item?.ticketNo };
        customArr.push(data);
      });
      setUnAssignedTicket(customArr);
    }
    setIsLoading(false);
  };

  const getTicketStats = async () => {
    setIsLoading(true);
    const res = await TicketAPIs.ticketStats();
    if (res) {
      setStats(res.data.data);
    }
  };

  useEffect(() => {
    getTicketStats();
    getUsers();
    getTickets();
  }, []);

  const columns = [
    {
      accessor: "ticketNo",
      Header: "Ticket No",
      Cell: ({ row }) => (
        <a
          onClick={(e) => {
            e.preventDefault();
            const url = `/dashboard/ticket-detail/${row.original.ticketNo}`;
            // Use a unique identifier for the window name, e.g., the ticket ID or a timestamp
            const windowName = `popUpWindow_${row.original.ticketNo}`;
            const windowSize = "width=800,height=600";
            window.open(url, windowName, windowSize);
          }}
          href={`/dashboard/ticket-detail/${row.original.ticketNo}`}
          rel="noopener noreferrer"
        >
          <span
            className="ticketNoCell"
            style={{
              backgroundColor:
                row.original.ticketType == "EMERGENCY" ||
                row.original.priority == "EMERGENCY"
                  ? "#e74c3c"
                  : row.original.ticketType == "EMERGENCY" ||
                    row.original.priority == "EMERGENCY"
                  ? "#45b39d"
                  : row.original.ticketType == "MULTIPLE" ||
                    row.original.priority == "MULTIPLE"
                  ? "#489ef3"
                  : row.original.ticketType == "PRIORITY" ||
                    row.original.priority == "PRIORITY"
                  ? "#f1948a"
                  : row.original.ticketType == "PROJECT WORK" ||
                    row.original.priority == "PROJECT WORK"
                  ? "#1b4f72"
                  : row.original.ticketType == "STANDARD" ||
                    row.original.priority == "STANDARD"
                  ? "#85929e"
                  : "#f5b041",
            }}
          >
            {row.original.ticketNo}
          </span>
        </a>
      ),
    },
    { accessor: "location", Header: "Location" },
    { accessor: "entered", Header: "Entered" },

    {
      accessor: "assigntoo",
      Header: "Assigned To",
      Cell: ({ row }) => <p>{row.original.assigntoo?.firstName}</p>,
    },
    {
      accessor: "assign",
      Header: "Assigned Date",
    },
    {
      accessor: "reassinged",
      Header: "Reassigned To",
      Cell: ({ row }) => <p>{row.original.reassigned?.firstName}</p>,
    },
    { accessor: "reassingndate", Header: "Reassigned Date" },
    { accessor: "due", Header: "Due" },
    { accessor: "completedDate", Header: "Completed" },
    {
      accessor: "code",
      Header: "Station Code",
      Cell: ({ row }) => (
        <p>{row.original.code?.map((item) => item.name).join(", ")}</p>
      ),
    },
    { accessor: "segmet", Header: "Segment" },
  ];

  /**
   * Re-Assign Ticket Submit Handler.
   */
  const reAssignTicket = async () => {
    if (!selectedUser) {
      toast.error("Please Select User to Assign");
      return;
    }
    if (!selectedIds[0]) {
      toast.error("No Tickets Selected.");
      return;
    }
    setIsLoading(true);
    const res = await TicketAPIs.assignReassignTicket({
      ticketId: selectedIds,
      userId: selectedUser,
      type: "re_assign",
      reAssignDate: endDate,
    });
    if (res) {
      // toast.success("Ticket Re-assigned Successfully");
      const selectedTicketsNumbers = searchedData
            .filter((item) => selectedIds.indexOf(item.id) > -1)
            .map((item) => item.ticketNo);
          setSuccessMsg(
            `Following Tickets ${selectedTicketsNumbers.join(
              ","
            )} has been re-assigned successfully.`
          );
          // getTickets()
    }
    setSearchedData([]);
    setSelectedIds([]);
    setSelectedUser(null);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <section>
          <Heading text={"Reassign Tickets"} />
          {successMsg && (
            <div className="successMsg">
              <strong style={{ margin: 0, color: "rgb(0,120,0)" }}>
                {successMsg}
              </strong>
              <MdClose
                size={20}
                color={"gray"}
                onClick={() => setSuccessMsg("")}
                style={{ position: "absolute", top: 10, right: 10 }}
              />
            </div>
          )}
          <Card>
            <SearchForm
              setSearchedData={setSearchedData}
              setIsLoading={setIsLoading}
              filters={{
                assignDate: "notNull",
                reAssignTo: null,
                isOfficeClear: false,
              }}
              loadDataOnMount={true}
              hiddenElements={[
                "digNearestIntersection",
                "ticketType",
                "typeOfWork",
                "isCompletedTicketOnly",
                "reAssignTo",
              ]}
              from={"ReAssign"}
            />
            <Form>
              <Row>
                <div className="headingBox">
                  <h6>Reassign Tickets</h6>
                </div>
                <Col className="column">
                  <Form.Group className="form-group">
                    <Form.Label>Reassign To</Form.Label>
                    <Select
                      options={users}
                      styles={customStyles}
                      defaultValue={users[0]}
                      onChange={(selectedOption) => {
                        setSelectedUser(selectedOption.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col className="column">
                  <Form.Group className="form-group">
                    <Form.Label>Reassigned Date</Form.Label>
                    <div className="datepicker_box">
                      <CustomDatePicker
                        selected={endDate}
                        onSelect={(date) => setEndDate(date)}
                        dateFormat="MM/dd/yyyy"
                      />
                      <span>
                        <MdDateRange />
                      </span>
                    </div>
                  </Form.Group>
                </Col>

                <Col className="mb-4 column" style={{ maxWidth: "160px" }}>
                  <Button
                    style={{ minHeight: "44px", marginTop: "30px" }}
                    onClick={() => {
                      reAssignTicket();
                    }}
                  >
                    <MdOutlineRefresh />
                    Reassign
                  </Button>
                </Col>
              </Row>
            </Form>
            {searchedData[0] ? (
              <SampleDataTable
                columns={columns}
                data={searchedData}
                onSelectionChange={handleSelectionChange}
              />
            ) : (
              <p style={{ textAlign: "center" }}>No Results Available</p>
            )}
            <Footer />
          </Card>
        </section>
      )}
    </>
  );
};

export default ResignTicket;
